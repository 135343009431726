<template>
  <div class=" overflow-hidden whitespace-nowrap w-full + p-[10px_0] mb-6">
    <div class="marquee flex w-[200%]">
      <div class="flex items-center gap-5 overflow-hidden w-[50%]">
        <div v-for="(icon, index) in icons" :key="index" v-html="icon" class="h-12 w-12 flex justify-center items-center"></div>
      </div>
      <div class="flex items-center gap-5 overflow-hidden w-[50%]" aria-hidden="true">
        <div v-for="(icon, index) in icons" :key="'duplicate-' + index" v-html="icon" class="h-12 w-12 flex justify-center items-center"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  scss,
  figma,
  react,
  vs_code,
  git,
  node_js,
  php,
  js,
  html,
  css,
} from "../../icons/Icons";

const icons = [
  scss,
  figma,
  react,
  vs_code,
  git,
  node_js,
  php,
  js,
  html,
  css,
   scss,
  figma,
  react,
  vs_code,
  git,
  node_js,
  php,
  js,
  html,
  css,
   scss,
  figma,
  react,
  vs_code,
  git,
  node_js,
  php,
  js,
  html,
  css,
   scss,
  figma,
  react,
  vs_code,
  git,
  node_js,
  php,
  js,
  html,
  css,
];
</script>

<style scoped>
.marquee {
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
</style>